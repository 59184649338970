import styled from '@emotion/styled';
import { sizes } from '@smartsheet/lodestar-core';

export const TrialModeContainer = styled.div(() => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: sizes.xLarge,
    borderRadius: sizes.xLarge,
    padding: `${sizes.xSmall}px ${sizes.small}px`,
    fontSize: '13px',
    fontWeight: 500,
}));
