import { createSelector } from 'reselect';
import { DynamicViewConfigSettings } from '../../common/enums/DynamicViewConfigSettings';
import { Feature } from '../../common/enums/Feature';
import { HomeFilter } from '../../common/enums/HomeFilter';
import { Bulletin } from '../../common/interfaces';
import { StoreState } from '../../store';
import { HomeSourceFilter } from './Reducers';

export const leftSidePanelOpenedSelector = (state: StoreState) => state.app.get('leftSidePanelOpened');
export const activeSidePanelSelector = (state: StoreState) => state.app.get('activeSidePanel');
export const appErrorSelector = (state: StoreState) => state.app.get('error');
export const appStageSelector = (state: StoreState) => state.app.get('stage');
export const appRedirectUrlSelector = (state: StoreState) => state.app.get('redirectUrl');
export const appActionInProgressMessageSelector = (state: StoreState) => state.app.get('actionInProgressMessage');
export const iframeStatusSelector = (state: StoreState) => state.app.get('inIframe');
export const notificationsSelector = (state: StoreState) => state.app.get('notifications');
export const homeFilterSelector = (state: StoreState) => state.app.get('homeFilter') as HomeFilter;
export const homeSourceFilterSelector = (state: StoreState) => state.app.get('homeSourceFilter') as HomeSourceFilter;

// Bulletins
export const bulletinsSelector = createSelector<StoreState, Map<Bulletin['id'], Bulletin>, Bulletin[]>(
    (state: StoreState) => state.app.get('bulletins'),
    (immutableBulletinsMap) => [...immutableBulletinsMap.values()]
);
export const isBulletinDisplayedSelector = (state: StoreState) => state.app.get('isBulletinDisplayed');

/// region Feature Flags
const flagsSelector = (state: StoreState) => state.app.get('featureFlags');

// DO NOT REMOVE - Example feature flag selector
export const fakeFeatureSelector = createSelector(flagsSelector, (flags) => flags.get(Feature.FAKE)?.isEnabled);

export const safeSharingFeatureSelector = createSelector(flagsSelector, (flags) => flags.get(Feature.SAFE_SHARING)?.isEnabled);
export const gsGetColumnsFeatureSelector = createSelector(flagsSelector, (flags) => flags.get(Feature.GS_GET_COLUMNS)?.isEnabled);
export const pollingDelayFeatureSelector = createSelector(flagsSelector, (flags) => flags.get(Feature.POLLING_DELAY));
export const workspaceSearchFeatureSelector = createSelector(flagsSelector, (flags) => flags.get(Feature.WORKSPACE_SEARCH)?.isEnabled);
export const paginationFeatureSelector = createSelector(flagsSelector, (flags) => flags.get(Feature.PAGINATION_1)?.isEnabled);
export const isEnabledLicensingDecoupleSelector = createSelector(flagsSelector, (flags) => flags.get(Feature.LICENSING_SERVICE_DECOUPLE)?.isEnabled);
export const attachmentAndConversationFeatureSelector = createSelector(
    flagsSelector,
    (flags) => flags.get(Feature.ATTACHMENTS_AND_CONVERSATIONS)?.isEnabled
);
export const isDisabledContactPickerOnDemandSelector = createSelector(
    flagsSelector,
    (flags) => flags.get(Feature.DISABLED_CONTACT_PICKER_ON_DEMAND)?.isEnabled
);
export const isDvAdminSharingUsageEnabledSelector = createSelector(
    flagsSelector,
    (flags) => flags.get(DynamicViewConfigSettings.DV_ADMIN_SHARING_USAGE)?.isEnabled
);
/// endregion
