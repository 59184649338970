import styled from '@emotion/styled';
import { sizes } from '@smartsheet/lodestar-core';

export const TrialModeList = styled.ul(`
    box-sizing: border-box;
    max-width: 400px;
    white-space: normal;
    margin: 0;
    padding: ${sizes.xxSmall}px;
    padding-left: ${sizes.medium}px;
    padding-right: 0px;
    line-height: 18px;
`);
