export interface ResourcePermalink {
    id: number;
    permalink: string;
}

export enum PermalinkType {
    SHEET = 'SHEET',
    REPORT = 'REPORT',
    WORKSPACE = 'WORKSPACE',
    FOLDER = 'FOLDER',
    DASHBOARD = 'DASHBOARD',
    CONTAINER = 'CONTAINER',
}

export interface PermalinkRequestItem {
    type: PermalinkType;
    id: number;
}
