import { SupportedLocales } from '@smartsheet/lodestar-core/dist/esm/i18n/constants';
import { createSelector } from 'reselect';
import { StoreState } from '../../store';
import { EligibilityStatus } from './types';
import { isUserViewPlanEligibleSelector } from '../View/Selectors';
import { isEnabledLicensingDecoupleSelector } from '../App/Selectors';

export const isAuthenticatedSelector = (state: StoreState) => state.auth.isAuthenticated;

export const userSelector = (state: StoreState) => state.auth.user;
export const isUserLicensedSelector = createSelector(userSelector, (user) => {
    return user.eligibility === EligibilityStatus.ALREADY_PURCHASED;
});
export const userEligibilitySelector = createSelector(userSelector, (user) => user.eligibility!);
export const isUserEligibleOrInTrial = createSelector(userEligibilitySelector, (eligibility) => {
    return eligibility !== undefined && [EligibilityStatus.PLAN_IS_ELIGIBLE, EligibilityStatus.ALREADY_IN_TRIAL].includes(eligibility);
});
export const userHasLicenseAccessSelector = createSelector(
    isUserLicensedSelector,
    isUserEligibleOrInTrial,
    (userLicensed, userEligibleOrInTrial) => userLicensed || userEligibleOrInTrial
);
export const isViewPlanOrUserEligible = createSelector(
    isEnabledLicensingDecoupleSelector,
    isUserViewPlanEligibleSelector,
    userHasLicenseAccessSelector,
    (isEnabledLicensingDecoupleFeature, isUserViewPlanEligible, userHasLicenseAccess) => {
        if (isEnabledLicensingDecoupleFeature) {
            return isUserViewPlanEligible;
        }
        return userHasLicenseAccess;
    }
);
export const isUserSystemAdmin = createSelector(userSelector, userHasLicenseAccessSelector, (user, isLicensed) => user.admin! && isLicensed);
export const userEmailSelector = createSelector(userSelector, (user) => user.email);
export const profilePicUrlSelector = createSelector(userSelector, (user) => user.profileImgUrl);
export const userFirstLastNameSelector = createSelector(userSelector, (user) => {
    return `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim();
});
export const userLocaleSelector = createSelector(userSelector, (user) => user.locale!);
export const shortLocaleSelector = createSelector(userSelector, (user) => user.locale!.slice(0, 2) as SupportedLocales);
export const userTimeZoneSelector = createSelector(userSelector, (user) => user.timeZone!);
