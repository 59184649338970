import * as React from 'react';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import { GradientBanner, ContentContainer, StyledH5, StyledUl } from './BannerContent';

interface Props {
    endDate?: string;
}

export default function ActSharingBanner(props: Props) {
    const { endDate } = props;
    const languageElements = useLanguageElements();

    return (
        <GradientBanner>
            <ContentContainer>
                <StyledH5>{languageElements.ACT_SHARING_BANNER_TITLE}</StyledH5>
                <StyledUl>
                    <li>{languageElements.ACT_SHARING_BANNER_BULLET_SYS_ADMIN_NOTIFIED}</li>
                    <li>{languageElements.ACT_SHARING_BANNER_BULLET_OTHERS_TRY}</li>
                    <li>{languageElements.ACT_SHARING_BANNER_BULLET_USAGE_END(endDate ?? '2025-04-01')}</li>
                </StyledUl>
            </ContentContainer>
        </GradientBanner>
    );
}
