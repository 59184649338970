import styled from '@emotion/styled';
import { colors, sizes } from '@smartsheet/lodestar-core';

interface ActiveProp {
    active: boolean;
}

export const TrialModeOrb = styled.div((props: ActiveProp) => ({
    boxSizing: 'border-box',
    display: 'inline-block',
    height: sizes.small,
    width: sizes.small,
    backgroundColor: props.active ? colors.buttonUpgrade : 'transparent',
    borderRadius: sizes.small,
    borderWidth: props.active ? '0px' : '1px',
    borderStyle: props.active ? 'none' : 'solid',
    borderColor: props.active ? 'transparent' : colors.neutralDark10,
    marginRight: sizes.xSmall,
}));
