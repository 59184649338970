import { Image, Row } from '../../common/interfaces';
import { AxiosError } from 'axios';
import { Epic, ofType } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { catchError, delay, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ActionType, UserAnalyticsAction } from '../../common/metrics/UserAnalyticsAction';
import { PermalinkRequestItem, PermalinkType } from '../../http-clients/interfaces/Permalink';
import { loggingClient } from '../../http-clients/Logging.client';
import viewClient from '../../http-clients/View.client';
import { ActionByType } from '../../store';
import { Actions as ImageActions } from '../../store/images/Actions';
import { Actions, ActionTypes } from './Actions';
import { SYNC_COMPLETE_ANIMATION_DURATION } from './Constants';
import { Actions as DetailsActions } from './Details/Actions';

const FILENAME = 'View/Epic.ts';
const PARTITION_SIZE = 5000;

/**
 * Fetch a single page of data for a view.
 * View data is fetched when a user navigates to a view, and fetched again when the user
 * navigates to a different view.
 */
export const fetchViewDataEpic: Epic<Actions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_VIEW_DATA),
        switchMap((action: ActionByType<Actions, ActionTypes.FETCH_VIEW_DATA>) => {
            let hasError = false;
            const start = Date.now();
            const { viewId, pagination } = action.payload;

            let params = {};
            if (pagination) {
                params = { page: 1 };
            }

            return from(viewClient.getViewData(viewId, params)).pipe(
                mergeMap((response) => {
                    const viewData = response.data;

                    // If the number of rows fetched is less than the total number of rows, fetch all rows
                    if (pagination && viewData.rows.length < viewData.totalRowCount) {
                        return of(Actions.fetchViewDataAll(viewId, viewData.totalRowCount, viewData));
                    } else {
                        return of(Actions.storeViewData(viewData));
                    }
                }),
                catchError((error: AxiosError) => {
                    hasError = true;
                    loggingClient.logError(FILENAME, 'fetchViewDataEpic', error);

                    return of(Actions.fetchingErrorViewData(error));
                }),
                finalize(() => {
                    UserAnalyticsAction.add(ActionType.DURATION, 'loadView.getViewData', {
                        duration: Date.now() - start,
                        hasError,
                    });
                })
            );
        })
    );

/**
 * Fetch all rows of data for a view.
 * View data is fetched when a user navigates to a view, and fetched again when the user
 * navigates to a different view.
 */
export const fetchViewDataAllEpic: Epic<Actions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_VIEW_DATA_ALL),
        switchMap((action: ActionByType<Actions, ActionTypes.FETCH_VIEW_DATA_ALL>) => {
            let hasError = false;
            const start = Date.now();
            const { viewId, totalRowCount } = action.payload;

            return from(viewClient.getViewData(viewId, { rowCount: totalRowCount })).pipe(
                mergeMap((response) => {
                    const viewData = response.data;

                    return of(Actions.storeViewData(viewData));
                }),
                catchError((error: AxiosError) => {
                    hasError = true;
                    loggingClient.logError(FILENAME, 'fetchViewDataAllEpic', error);

                    return of(Actions.fetchingErrorViewData(error));
                }),
                finalize(() => {
                    UserAnalyticsAction.add(ActionType.DURATION, 'loadView.getViewDataAll', {
                        duration: Date.now() - start,
                        hasError,
                    });
                })
            );
        })
    );

export const storeViewDataEpic: Epic<Actions | ImageActions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.STORE_VIEW_DATA),
        switchMap((action: ActionByType<Actions, ActionTypes.STORE_VIEW_DATA>) => {
            const viewData = action.payload.viewData;
            const seenContainerIds = new Set<number>();
            const gridImages: Image[] = [];

            // Extract container IDs and Image URLs from viewData
            viewData.rows?.forEach((row: Row) => {
                row.cells?.forEach((cell) => {
                    if (cell.containerId) {
                        seenContainerIds.add(cell.containerId);
                    }
                    if (cell.image) {
                        gridImages.push(cell.image);
                    }
                });
            });

            const actionsToDispatch = [];

            if (seenContainerIds.size > 0) {
                actionsToDispatch.push(Actions.fetchContainerLinks(viewData.viewId, Array.from(seenContainerIds)));
            }

            if (gridImages.length > 0) {
                actionsToDispatch.push(ImageActions.fetchImageUrls(gridImages));
            }

            return actionsToDispatch.length > 0 ? of(...actionsToDispatch) : EMPTY;
        })
    );

/**
 * View row is fetched when a user performs an upsert on a row
 */
export const fetchGridRowEpic: Epic<Actions | DetailsActions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_GRID_ROW),
        switchMap((action: ActionByType<Actions, ActionTypes.FETCH_GRID_ROW>) => {
            const { viewId, rowId, isUpdate, startTimeForSyncProcess } = action.payload;

            return from(viewClient.getGridRow(viewId, rowId)).pipe(
                mergeMap((response) => {
                    const actions: Array<Actions | DetailsActions> = [DetailsActions.upsertViewRowRemoveDone({ viewId, rowId })];

                    if (isUpdate) {
                        actions.push(Actions.updateGridRow(viewId, response.data));
                    } else {
                        actions.push(Actions.addGridRow(viewId, response.data));
                    }

                    return actions;
                }),
                tap(() => {
                    const duration = Date.now() - startTimeForSyncProcess;
                    const message = 'Sync row - Success';
                    loggingClient.logInfo({ file: FILENAME, message, viewId, rowId, duration, isNewSubmission: !isUpdate });

                    UserAnalyticsAction.add(ActionType.DURATION, 'SyncRowComplete', {
                        duration,
                        hasError: false,
                    });
                }),
                delay(SYNC_COMPLETE_ANIMATION_DURATION),
                catchError((error: AxiosError) => {
                    loggingClient.logError(FILENAME, 'fetchGridRowEpic', error, { viewId, rowId, isNewSubmission: !isUpdate });

                    const duration = Date.now() - startTimeForSyncProcess;
                    UserAnalyticsAction.add(ActionType.DURATION, 'SyncRowComplete', {
                        duration,
                        hasError: true,
                    });
                    return of(Actions.fetchingErrorGridRow(viewId, rowId));
                })
            );
        })
    );

/**
 * View config is fetched when a user navigates to a view, and fetched again when the user
 * navigates to a different view.
 */
export const fetchConfigEpic: Epic<Actions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_VIEW_CONFIG),
        switchMap((action: ActionByType<Actions, ActionTypes.FETCH_VIEW_CONFIG>) => {
            let hasError = false;
            const start = Date.now();
            const { viewId } = action.payload;

            return from(viewClient.getViewConfig(viewId)).pipe(
                map((response) => {
                    return Actions.storeConfig(response.data);
                }),
                catchError((error: AxiosError) => {
                    hasError = true;
                    loggingClient.logError(FILENAME, 'fetchConfigEpic', error);

                    return of(Actions.fetchingErrorConfig(error));
                }),
                finalize(() => {
                    UserAnalyticsAction.add(ActionType.DURATION, 'loadView.getViewConfig', {
                        duration: Date.now() - start,
                        hasError,
                    });
                })
            );
        })
    );

export const fetchContainerLinksEpic: Epic<Actions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_CONTAINER_LINKS),
        switchMap((action: ActionByType<Actions, ActionTypes.FETCH_CONTAINER_LINKS>) => {
            const { viewId, containerIds } = action.payload;

            const partitions: PermalinkRequestItem[][] = [];
            for (let i = 0; i < containerIds.length; i += PARTITION_SIZE) {
                const partition = containerIds.slice(i, i + PARTITION_SIZE).map((id: number) => ({ type: PermalinkType.CONTAINER, id }));
                partitions.push(partition);
            }

            return from(
                (async () => {
                    try {
                        const responses = await Promise.all(partitions.map((items) => viewClient.getContainerLinks(viewId, items)));

                        const containerLinkMap: Map<number, string> = new Map();
                        responses.forEach((response) => {
                            response.data?.forEach((containerLink) => {
                                containerLinkMap.set(containerLink.id, containerLink.permalink);
                            });
                        });

                        if (containerLinkMap.size === 0) {
                            loggingClient.logError(FILENAME, 'fetchContainerLinksEpic', 'Failed to fetch container links.');
                            return Actions.fetchingErrorContainerLinks(viewId, containerIds);
                        } else {
                            return Actions.storeContainerLinks(viewId, containerLinkMap);
                        }
                    } catch (error: any) {
                        loggingClient.logError(FILENAME, 'fetchContainerLinksEpic', error);
                        return Actions.fetchingErrorContainerLinks(viewId, containerIds);
                    }
                })()
            );
        })
    );

export const fetchViewEligibilityEpic: Epic<Actions> = (action$) =>
    action$.pipe(
        ofType(ActionTypes.FETCH_VIEW_ELIGIBILITY),
        switchMap((action: ActionByType<Actions, ActionTypes.FETCH_VIEW_ELIGIBILITY>) => {
            let hasError = false;
            const start = Date.now();
            const { viewId } = action.payload;

            return from(viewClient.getViewEligibility(viewId)).pipe(
                map((response) => {
                    return Actions.storeEligibility(response.data);
                }),
                catchError((error: AxiosError) => {
                    hasError = true;
                    loggingClient.logError(FILENAME, 'fetchEligibilityEpic', error);

                    return of(Actions.fetchingErrorEligibility(error));
                }),
                finalize(() => {
                    UserAnalyticsAction.add(ActionType.DURATION, 'loadView.getViewEligibility', {
                        duration: Date.now() - start,
                        hasError,
                    });
                })
            );
        })
    );