import * as React from 'react';
import AccountMenu from './AccountMenu/AccountMenu';
import DynamicViewTitle from './DynamicViewTitle/DynamicViewTitle';
import './Header.css';
import Help from './Help/Help';
import Notification from './Notification/Notification';
import OwnershipTransfersBadge from './OwnershipTransfer/OwnershipTransfersBadge';
import { TrialModeIndicator } from './TrialMode';
import { isDvAdminSharingUsageEnabledSelector, leftSidePanelOpenedSelector } from '../../containers/App/Selectors';
import { useSelector } from 'react-redux';
import { ActModeIndicator } from './ActModeIndicator';
import styled from '@emotion/styled';

interface HeaderProps {
    enabled: boolean;
}

const Header: React.FC = () => {
    const isTrueUpFeatureEnabled = useSelector(isDvAdminSharingUsageEnabledSelector);
    const leftSidePanelOpened = useSelector(leftSidePanelOpenedSelector);
    return (
        <HamburgerSpacer className="header" enabled={!leftSidePanelOpened}>
            <Notification />
            <DynamicViewTitle />
            <div className="rightItems">
                {isTrueUpFeatureEnabled && <ActModeIndicator />}
                {!isTrueUpFeatureEnabled && <TrialModeIndicator />}
                <Help />
                <OwnershipTransfersBadge />
                <AccountMenu />
            </div>
        </HamburgerSpacer>
    );
};

const HamburgerSpacer = styled.header<HeaderProps>((props) => ({
    marginLeft: props.enabled ? '50px' : '0',
    paddingLeft: props.enabled ? '0' : '30px',
}));

export default Header;
