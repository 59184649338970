import * as React from 'react';
import { useLanguageElements } from '../../../language-elements/withLanguageElementsHOC';
import { ContentContainer, StyledH5, StyledUl, SolidBanner } from './BannerContent';

interface Props {
    adminEmail?: string;
}
export const AceBannerContent = (props: Props) => {
    const { adminEmail } = props;
    const languageElements = useLanguageElements();

    return (
        <SolidBanner>
            <ContentContainer>
                <StyledH5>{languageElements.EVALUATION_SHARING_BANNER_TITLE}</StyledH5>
                <StyledUl>
                    <li>{languageElements.EVALUATION_SHARING_BANNER_BULLET_SYS_ADMIN_NOTIFIED}</li>
                    <li>
                        {adminEmail
                            ? languageElements.EVALUATION_SHARING_BANNER_BULLET_CONTACT_SYS_ADMIN_EMAIL_ADDRESS(adminEmail)
                            : languageElements.EVALUATION_SHARING_BANNER_BULLET_CONTACT_SYS_ADMIN}
                    </li>
                </StyledUl>
            </ContentContainer>
        </SolidBanner>
    );
};
