import styled from '@emotion/styled';
import { colors, sizes } from '@smartsheet/lodestar-core';
import { color as tokenColor } from '@smartsheet/design-tokens-react';

const BannerBase = styled.div`
    padding: ${sizes.large - sizes.xxSmall}px ${sizes.large - sizes.xxSmall}px ${sizes.small}px ${sizes.large - sizes.xxSmall}px;
    margin-bottom: ${sizes.small}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${colors.neutralDark40};
`;

export const GradientBanner = styled(BannerBase)`
    background-color: ${tokenColor('gradient--upsell')};
`;

export const SolidBanner = styled(BannerBase)`
    background-color: ${colors.visGreenLight30};
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    max-width: 550px;
`;

export const StyledH5 = styled.h5`
    margin: 0;
    font-size: 15px;
    font-weight: 600;
`;

export const StyledUl = styled.ul`
    margin-top: ${sizes.small}px;
    padding-left: ${sizes.large}px;
    font-size: 13px;
    font-weight: 400;
`;
