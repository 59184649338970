import styled from '@emotion/styled';
import * as React from 'react';
import { colors } from '@smartsheet/lodestar-core';
import { color as tokenColor } from '@smartsheet/design-tokens-react';
import { EligibilityStatus } from '../../containers/Auth/types';
import { useLanguageElements } from '../../language-elements/withLanguageElementsHOC';
import { useSelector } from 'react-redux';
import { userEligibilitySelector } from '../../containers/Auth/Selectors';
import { TrialModeContainer } from './TrialModeIndicator/TrialModeContainer';
import { TrialModeOrb } from './TrialModeIndicator/TrialModeOrb';

const ActTrialModeContainer = styled(TrialModeContainer)`
    background-color: ${colors.visBlueLight30};
    color: ${tokenColor('color--text--primary--strong')};
`;

const ActTrialModeOrb = styled(TrialModeOrb)`
    background-color: ${tokenColor('color--text--primary--strong')};
    border-color: ${tokenColor('color--text--primary--strong')};
`;

export const ActModeIndicator = () => {
    const languageElements = useLanguageElements();
    const authStatus = useSelector(userEligibilitySelector);

    // TODO: only display when conditions are met -- after fetching USM license status

    const activeTrial = authStatus === EligibilityStatus.ALREADY_IN_TRIAL;
    return (
        <React.Fragment>
            <ActTrialModeContainer>
                <ActTrialModeOrb active={activeTrial} />
                {languageElements.ACT_CHIP_LABEL(1)}
            </ActTrialModeContainer>
        </React.Fragment>
    );
};
